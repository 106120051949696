import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
import vuetify from '@/plugins/vuetify';
import store from './store';
import instanceAxios from './config/axios';
import App from './App.vue';
import router from './router';

Vue.use(router);

Vue.config.productionTip = false;
Vue.prototype.$http = instanceAxios;

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h) => h(App),
    router,
    vuetify,
    store,
  },
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
