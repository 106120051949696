import actions from '@/store/permission/actions';
import getters from '@/store/permission/getters';
import mutations from '@/store/permission/mutations';
import initialState from './state';

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters,
};
