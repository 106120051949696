import Vue from 'vue';
import VueRouter from 'vue-router';
import { configureBeforeEachRouter } from '@zitrus/utils-service';
import MenuView from '../views/menu-view.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/ressus/**',
    name: 'RessusMenu',
    component: MenuView,
    meta: {
      applicationKey: 'ressus',
    },
  },
  {
    path: '/workspace/**',
    name: 'WorkspaceMenu',
    component: MenuView,
    meta: {
      applicationKey: 'workspace',
    },
  },
  {
    path: '/**',
    name: 'DefaultMenu',
    component: MenuView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => configureBeforeEachRouter(router, to, from, next));

export default router;
