<template>
  <v-navigation-drawer
    v-if="!$route.query?.hideMenu"
    :expand-on-hover="!isFixed"
    app
    class="pa-0 menu-view"
    dark
    left
    mini-variant-width="96"
    permanent
    width="342"
  >
    <div class="flex items-center h-[96px] mb-6">
      <div v-if="isFixed" class="flex items-center px-10">
        <img :src="findLogoByKey()" alt="Logo" contain height="50px" max-width="220px" />
        <v-spacer />
        <v-btn class="!p-0" plain @click="onFixed">
          <img
            alt="Desafixar Menu"
            class="cursor-pointer"
            height="20"
            max-width="20"
            src="@/assets/unpin-menu.svg"
          />
        </v-btn>
      </div>
      <v-list v-else class="flex items-center justify-center px-6 group">
        <v-list-item
          :ripple="false"
          class="group-hover:!text-primary-blue-disabled"
          @click="onFixed"
        >
          <v-list-item-icon>
            <v-icon class="group-hover:!text-primary-blue-disabled">mdi-menu</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Fixar Menu</v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
    <v-list>
      <v-layout v-if="isRouting || isLoadingPermissions" align-center justify-center>
        <v-progress-circular indeterminate></v-progress-circular>
      </v-layout>
      <div v-else>
        <div v-for="item in paths" :key="item.title">
          <div v-if="!item.items" class="px-6">
            <v-list-item
              :key="item.title"
              :ripple="false"
              :to="item.path"
              exact
              link
              @click="collapseItems"
            >
              <v-list-item-icon>
                <v-icon>{{ item.action }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="item.title" />
            </v-list-item>
          </div>
          <v-list-group
            v-else
            v-model="item.active"
            :prepend-icon="item.action"
            :ripple="false"
            class="px-6"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title :to="item.path" v-text="item.title" />
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :ripple="false"
              :to="child.path"
              link
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title" />
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import logos from '@/utils/logos';
import { emitEvent } from '@/events/custom-events-service';

export default {
  name: 'MenuView',
  data() {
    return {
      isFixed: false,
      isRouting: false,
      items: [],
    };
  },
  computed: {
    ...mapState('menu/permissions', ['isLoadingPermissions', 'workspaceRoutes', 'ressusRoutes']),
    ...mapGetters('menu/permissions', ['pathsAvailable']),
    applicationKey() {
      return this.$route?.meta?.applicationKey || '';
    },
    paths() {
      return this.pathsAvailable(this.applicationKey) || [];
    },
  },
  watch: {
    async applicationKey(value) {
      if (value && value !== '') {
        await this.getAllowedPaths({ applicationKey: value });
      }
    },
  },
  created() {
    this.$watch(
      () => this.$route,
      () => {
        this.isRouting = true;
        this.items = [...this.pathsAvailable(this.applicationKey)];
        this.identifyActiveRoute(this.$route.path);
        this.isRouting = false;
      },
      { deep: true, immediate: true },
    );
  },
  async mounted() {
    const hasPermissions = this[`${this.applicationKey}Routes`]?.length > 0;

    if (this.applicationKey && this.applicationKey !== '' && !hasPermissions) {
      await this.getAllowedPaths({ applicationKey: this.applicationKey });
    }
  },
  methods: {
    ...mapActions('menu/permissions', ['getAllowedPaths']),
    findLogoByKey() {
      const logo = logos[this.applicationKey];
      return logo != null ? logo : logos.workspace;
    },
    onFixed() {
      this.isFixed = !this.isFixed;

      emitEvent('@zitrus/z-menu/action-click-fixed-menu', this.isFixed);
    },
    collapseItems() {
      this.items = this.items.map((item) => ({
        ...item,
        active: false,
      }));
    },
    identifyActiveRoute(currentRoute) {
      this.items = this.items.map((item) => {
        if (item.items && item.items?.length > 0) {
          this.$set(
            item,
            'active',
            !!item.items.find((subItem) => currentRoute.startsWith(subItem.path)),
          );
        } else if (currentRoute.startsWith(item.path)) {
          this.$set(item, 'active', true);
        }

        return item;
      });
    },
  },
};
</script>

<style lang="scss">
@import 'src/styles/global.scss';

#z-menu {
  .menu-view.v-navigation-drawer {
    display: block !important;
    position: relative !important;
    background-color: theme('colors.primary-blue-base') !important;

    .v-list-item__action,
    .v-list-item__icon {
      margin-right: 0 !important;
    }

    .v-size--default {
      min-width: 0 !important;
    }

    .v-list-item__title {
      margin-left: 24px;
      font-size: 14px;
      text-wrap: wrap !important;
    }

    .v-list-group--no-action > .v-list-group__items > .v-list-item {
      padding-left: 40px;

      .v-list-item__title {
        font-size: 12px;
      }

      &:before {
        margin: 0 16px 0 40px;
      }
    }

    .v-list-group--active {
      border: none !important;
    }

    .v-list-item {
      min-height: 20px;
    }

    .v-list-item--link {
      &::before {
        border-radius: 17px;
      }
    }

    .v-list-item--active {
      color: theme('colors.primary-blue-base') !important;
      font-weight: 700;

      i,
      .v-list-item__icon,
      .v-list-item__title {
        color: theme('colors.secondary-lemon-brand') !important;
      }

      &::before {
        opacity: 0;
      }
    }

    .v-list-item--active:not(.v-list-group__header) {
      background-color: theme('colors.primary-blue-dark') !important;
      border-radius: 17px;

      .v-list-item__content {
        background-color: theme('colors.primary-blue-dark') !important;
        border-radius: 17px;
      }

      i,
      .v-list-item__title {
        color: theme('colors.white') !important;
      }
    }

    .v-list-group--active {
      border-top: 1px solid theme('colors.neutral-gray-medium');
      border-bottom: 1px solid theme('colors.neutral-gray-medium');
      padding-bottom: 10px;

      .v-list-item--active {
        background-color: theme('colors.primary-blue-base') !important;
      }
    }
  }

  .menu-view.v-navigation-drawer--mini-variant {
    .v-list-group--active {
      border: 0 !important;

      .v-list-item--active {
        background-color: theme('colors.primary-blue-dark') !important;
        border-radius: 17px;

        i {
          height: 20px !important;
          color: theme('colors.white') !important;
        }
      }
    }
  }
}
</style>
