<template>
  <div id="z-menu">
    <router-view v-bind:mini-variant-width="96" />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss" scoped>
#z-menu {
  :deep(.v-application--wrap) {
    min-height: fit-content !important;
    width: fit-content !important;
  }
}
</style>
