import { zPermissionApi } from '@/api';
import { createCommandService } from '@/services';

export const getUserPermissions = async ({
  applicationKey,
  action,
  keys,
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  createCommandService({
    method: 'get',
    uri: `${zPermissionApi}/aplicacao/${applicationKey}/usuario-perfil/permissao/${action}?${keys}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export default { getUserPermissions };
