import { formatPermissionKeysToSearch, ressusRoutes, workspaceRoutes } from '@/utils/routes';

const permissionsToSearch = () => ({
  workspace: [...formatPermissionKeysToSearch(workspaceRoutes)],
  ressus: [...formatPermissionKeysToSearch(ressusRoutes)],
});

const paths = {
  workspace: [...workspaceRoutes],
  ressus: [...ressusRoutes],
};

const pathsAvailable = (state) => (applicationKey) => {
  const applicationPaths = paths[applicationKey] ?? [];
  const pathsPermissionMapped = state[`${applicationKey}Routes`] ?? [];

  return applicationPaths
    ?.map((path) => {
      if (Array.isArray(path?.items)) {
        const allowedItems = [];

        path?.items.forEach((item) => {
          const permission = pathsPermissionMapped?.find(
            ({ featureName }) => item.permissionKey === featureName,
          );

          if (permission?.hasPermission) {
            allowedItems.push(item);
          }
        });

        return { ...path, items: allowedItems };
      }

      const permission = pathsPermissionMapped?.find(
        ({ featureName }) =>
          path.permissionKey === featureName || path.permissionKey === 'menu_dashboard',
      );

      return permission?.hasPermission || permission?.featureName === 'menu_dashboard'
        ? { ...path }
        : null;
    })
    .filter((path) => path != null && (path?.items?.length > 0 || path?.items === undefined));
};

export default { permissionsToSearch, pathsAvailable };
