const ressusRoutes = [
  {
    action: 'mdi-chart-box-outline',
    title: 'Painel',
    path: '/ressus/dashboard',
    permissionKey: 'menu_dashboard',
  },
  {
    action: 'mdi-plus-box-outline',
    title: 'Cadastros',
    items: [
      {
        title: 'Alegação ANS',
        path: '/ressus/alegacao-ans',
        permissionKey: 'menu_alegacao_ans',
      },
      {
        title: 'Caráter de atendimento',
        path: '/ressus/carater-atendimento',
        permissionKey: 'menu_carater_atendimento',
      },
      {
        title: 'Detalhamento do motivo',
        path: '/ressus/detalhamento-motivo',
        permissionKey: 'menu_detalhamento_motivo',
      },
      { title: 'Empresa', path: '/ressus/empresa', permissionKey: 'menu_empresa' },
      {
        title: 'Motivo impugnação/recurso',
        path: '/ressus/motivo-impugnacao',
        permissionKey: 'menu_motivo_impugnacao',
      },
      {
        title: 'Evento contábil',
        path: '/ressus/evento-contabil',
        permissionKey: 'menu_evento_contabil',
      },
      {
        title: 'Grupo de trabalho',
        path: '/ressus/grupo-trabalho',
        permissionKey: 'menu_grupo_trabalho',
      },
    ],
  },
  {
    action: 'mdi-lan',
    title: 'Parâmetros',
    items: [
      { title: 'Instância', path: '/ressus/instancia', permissionKey: 'menu_instancia' },
      { title: 'Integração', path: '/ressus/integracao', permissionKey: 'menu_integracao' },
      {
        title: 'Procedimentos TUSS x SIGTAP',
        path: '/ressus/procedimentos-tuss-sigtap',
        permissionKey: 'menu_procedimentos_tuss_sigtap',
      },
      {
        title: 'Críticas de validação',
        path: '/ressus/criticas-validacao',
        permissionKey: 'menu_criticas_validacao',
      },
      {
        title: 'Contabilidade',
        path: '/ressus/contabilidade',
        permissionKey: 'menu_contabilidade',
      },
    ],
  },
  {
    action: 'mdi-checkbox-blank-badge-outline',
    title: 'Ofícios notificados',
    path: '/ressus/oficios-notificacao',
    permissionKey: 'menu_oficios_notificacao',
  },
  {
    action: 'mdi-checkbox-marked-circle-outline',
    title: 'Processos',
    items: [
      {
        title: 'Resumos ABI',
        path: '/ressus/resumo-abi/',
        permissionKey: 'menu_resumo_abi',
      },
      {
        title: 'Atendimentos',
        path: '/ressus/atendimentos-abi/',
        permissionKey: 'menu_atendimentos_abi',
      },
      {
        title: 'Protocolos eletrônicos',
        path: '/ressus/protocolos-eletronicos/',
        permissionKey: 'menu_protocolos_eletronicos',
      },
      {
        title: 'Documentos gerados',
        path: '/ressus/documentos-gerados',
        permissionKey: 'menu_documentos_gerados',
      },
      {
        title: 'Lançamentos contábeis',
        path: '/ressus/lancamentos-contabeis',
        permissionKey: 'menu_lancamentos_contabeis',
      },
      {
        title: 'Solicitação de documentos',
        path: '/ressus/solicitacao-documentos',
        permissionKey: 'menu_solicitacao_documentos',
      },
    ],
  },
  {
    action: 'mdi-file-document-multiple-outline',
    title: 'Relatórios',
    items: [
      {
        title: 'Indicadores ressarcimento',
        path: '/ressus/indicadores-ressarcimento',
        permissionKey: 'menu_indicadores_ressarcimento',
      },
      {
        title: 'Lançamentos contábeis',
        path: '/ressus/relatorio-contabil',
        permissionKey: 'menu_relatorio_contabil',
      },
      {
        title: 'Declaração beneficiários',
        path: '/ressus/declaracao-beneficiarios',
        permissionKey: 'menu_declaracao_beneficiarios',
      },
      {
        title: 'Atendimentos',
        path: '/ressus/relatorio-atendimentos',
        permissionKey: 'menu_relatorio_atendimentos',
      },
    ],
  },
];

const workspaceRoutes = [
  {
    action: 'mdi-plus-box-outline',
    title: 'Cadastros',
    items: [
      {
        title: 'Cadastro do perfil',
        path: '/workspace/perfil',
        permissionKey: 'perfil',
      },
      {
        title: 'Cadastro do usuário',
        path: '/workspace/usuario',
        permissionKey: 'usuario',
      },
      {
        title: 'Cadastro da integração',
        path: '/workspace/integracao',
        permissionKey: 'integracao',
      },
    ],
  },
  {
    action: 'mdi-lock-open-check-outline',
    title: 'LGPD',
    items: [
      {
        title: 'Termos de uso e aviso de privacidade',
        path: '/workspace/lgpd/termos',
        permissionKey: 'lgpd_termos',
      },
      {
        title: 'Auditoria',
        path: '/workspace/lgpd/auditoria',
        permissionKey: 'lgpd_auditoria',
      },
    ],
  },
];

const formatPermissionKeysToSearch = (permissions) => {
  if (!Array.isArray(permissions)) {
    return [];
  }

  const formattedPermissions = [];

  permissions.forEach((permission) => {
    if (Array.isArray(permission?.items)) {
      permission?.items.forEach(({ path, permissionKey }) =>
        formattedPermissions.push({ key: permissionKey, path }),
      );
    } else {
      formattedPermissions.push({ key: permission?.permissionKey, path: permission?.path });
    }
  });

  return formattedPermissions;
};

export { ressusRoutes, workspaceRoutes, formatPermissionKeysToSearch };
