import Vue from 'vue';
import { getUserPermissions } from '@/services/z-permission';

const createPermissionKeysQueryParams = (permissions) => {
  if (!Array.isArray(permissions) || permissions.length < 1) {
    return '';
  }

  const urlSearchParams = new URLSearchParams('?');

  permissions.forEach(({ key }) => urlSearchParams.append('funcionalidade', key));

  return urlSearchParams;
};

const getAllowedPaths = async ({ commit, getters }, { applicationKey }) => {
  commit('GENERIC_MUTATION', {
    property: 'isLoadingPermissions',
    payload: true,
  });

  const { permissionsToSearch } = getters;

  await getUserPermissions({
    applicationKey,
    action: 'view',
    keys: createPermissionKeysQueryParams(permissionsToSearch[applicationKey] ?? []),
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: `${applicationKey}Routes`,
        payload: data ?? [],
      });
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(
        message ??
          'Ocorreu um erro de requisição ao buscar as permissões do usuário nas opções do menu',
      );
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'isLoadingPermissions',
        payload: false,
      });
    },
  });
};

export default { getAllowedPaths };
