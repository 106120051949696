import Vue from 'vue';
import Vuex from 'vuex';
import menu from '@/store/menu';

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  modules: { menu },
});
