import Vue from 'vue';

const httpMethods = {
  get: 'get',
  post: 'post',
  patch: 'patch',
  put: 'put',
  delete: 'delete',
};

export const applyCatchError = (error) => error;

export const createCommandService = async ({
  method = httpMethods.get,
  uri,
  payload,
  onStart,
  onSuccess,
  onCustomError,
  onFinally,
  config = null,
}) => {
  try {
    config?.debug && console.warn(method, uri, payload);

    if (!httpMethods[method]) {
      throw new Error(`Método HTTP inválido. Use: ${Object.values(httpMethods)}.`);
    }

    if (!uri) {
      throw new Error('O parâmetro "uri" é requerido para criação da instância do serviço.');
    }

    if (method === httpMethods.get && payload) {
      throw new Error('O método "get" não pode enviar conteúdo no corpo da requisição.');
    }

    onStart?.();

    return Vue.prototype.$http[method](uri, payload, config)
      .then(onSuccess)
      .catch(onCustomError || applyCatchError)
      .finally(onFinally);
  } catch (thrown) {
    console.error(thrown);
    return null;
  }
};
